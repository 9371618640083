const getParam = (title) => {
  return title.replace(/[^a-zA-Z0-9]+/g, "");
};
const getAnchor = (title) => {
  return title.replaceAll(" ", "-");
};
const removeTrailingSlash = (str) => {
  return str.replace(/\/+$/, "");
};
const getRootPath = (current) => {
  var firstSlash = current.indexOf("/"),
    firstValidSlash = current.indexOf("/", firstSlash + 2),
    path = current.substr(0, firstValidSlash);
  return path;
};
export { getParam, getAnchor, getRootPath, removeTrailingSlash };
