import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { PropTypes } from "prop-types";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    backgroundColor: "#fdb3d7",
    // backgroundImage: `url(${Image})`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    backgroundColor: "#fdb3d7",
    // backgroundImage: `url(${Image})`,
  },
}));

export default createMuiTheme({
  overrides: {
    MuiInput: {
      root: {
        borderRadius: 10,
        backgroundImage: "../images/bgdropdown.png",
        borderBottom: 10,
        margin: 10,
      },
    },
  },
});

const OptionGroup = ({ name, persons, i }) => {
  return (
    <optgroup label={name}>
      {persons.map((person, index) => (
        <Option {...person} i={index} gi={i} key={"og" + i + "o" + index} />
      ))}
    </optgroup>
  );
};

OptionGroup.propTypes = {
  group: PropTypes.object,
};

const Option = ({ name }) => {
  return (
    <option value={name} className="option">
      {name}
    </option>
  );
};

const Dropdown = ({ groups, setPerson, person }) => {
  const classes = useStyles();
  let optArray = [];

  for (let groupIndex in groups) {
    for (let personIndex in groups[groupIndex].persons) {
      optArray.push(groups[groupIndex].persons[personIndex]);
    }
  }

  const handleChange = (e) => {
    let options = e.currentTarget.options;
    let p = optArray.filter(function (person) {
      return person.name === options[options.selectedIndex].innerText;
    });
    setPerson(p[0]);
  };

  return (
    <FormControl variant="standard" className={classes.formControl}>
      <InputLabel className="iLabel" htmlFor="filled-person-simple">
        Search
      </InputLabel>
      <Select
        native
        value={person ? person.name : ""}
        onChange={handleChange}
        inputProps={{
          name: "person",
          id: "filled-person-simple",
        }}
      >
        <option aria-label="None" value={""} key={"empty"}></option>
        {groups.map((group, index) => (
          <OptionGroup {...group} i={index} key={"og" + index} />
        ))}
      </Select>
    </FormControl>
  );
};

Dropdown.propTypes = {
  groups: PropTypes.array,
  setPerson: PropTypes.func,
  person: PropTypes.object,
};
export { Dropdown };
