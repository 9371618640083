import Link from "@material-ui/core/Link";
import { getParam, getAnchor } from "../common/util.js";
import Typography from "@material-ui/core/Typography";
import { getRootPath } from "../common/util";

const BASE_URL = getRootPath(window.location.href);

const Links = ({ person }) => {
  let linkpages = person.pages.map((linkpage) => {
    linkpage.link = getParam(linkpage.page);
    return linkpage;
  });

  const name = getAnchor(person.name);

  return linkpages.map((linkpage) => (
    <Typography variant="h5" key={linkpage.link}>
      <Link
        className="card-link"
        // href={BASE_URL + "/?page=" + linkpage.link + "?a=" + name}
        href={BASE_URL + "/" + linkpage.link + "?a=" + name}
      >
        {linkpage.page}
      </Link>
    </Typography>
  ));
};

// Functional component
const Card = ({ person }) => {
  return (
    <>
      <div className="card">
        <img
          alt="{person.name}"
          className="card-img-top"
          src={BASE_URL + "/app/images/" + person.image}
        ></img>

        <div className="card-body">
          <h3 className="card-title">{person ? person.name : null}</h3>
          <h4 className="card-text">{person ? person.role : null}</h4>
          <h5 className="card-text">References:</h5>
          <div className="card-text">
            <Links person={person} />
          </div>
        </div>
      </div>
    </>
  );
};

export { Card };
