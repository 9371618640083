import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Influencers from "./Influencers";
import { removeTrailingSlash } from "./common/util";
import Empty from "./Empty";
import reportWebVitals from "./reportWebVitals";

function Search() {
  // if (
  //   removeTrailingSlash(window.location.href) ===
  //   removeTrailingSlash(window.origin)
  // ) {
  //   return <Influencers />;
  // } else {
  return <Empty />;
  // }
}

ReactDOM.render(<Search />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
