import React from "react";

function Empty() {

  return ( 
    <></>
  );
}

export default Empty;
