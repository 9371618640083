import React, { useState, useEffect } from "react";
import "./Influencers.css";
import { fetchData } from "./data/api.js";
import { Card } from "./components/Card";
import { Dropdown } from "./components/Dropdown";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import isEmpty from "lodash/isEmpty";

const useStyles = makeStyles((theme) => ({
  appControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Influencers() {
  const [allGroups, setAllGroups] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState({});

  const classes = useStyles();

  const getData = () => {
    fetchData()
      .then((response) => {
        setAllGroups(response.data.groups);
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container variant="filled" className={classes.appControl}>
      <Dropdown
        groups={allGroups}
        setPerson={setSelectedPerson}
        person={selectedPerson}
      />
      {!isEmpty(selectedPerson) && <Card person={selectedPerson} />}
    </Container>
  );
}

export default Influencers;

